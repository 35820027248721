import React from 'react';
import {
  Flex,
  Link,
  Text,
  Heading,
  useTheme,
} from '@chakra-ui/react';

export default function NotFoundPage() {
  const theme = useTheme();
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minH={`calc(100vh - ${theme.sizes['14']})`}
    >
      <Heading>
        4
        <span role="img" aria-label="Crying Face">
          😢
        </span>
        4
      </Heading>
      <Text
        sx={{
          my: 3,
        }}
      >
        Page not found.
      </Text>
      <Link href="/" variant="now" colorScheme="now">
        Return to Home Page
      </Link>
    </Flex>
  );
}
